/* -------------------------------------------------------------------------- */
/*                                 step wizard                                */
/* -------------------------------------------------------------------------- */
const wizardInit = () => {
    function refreshCreditCounters(starterCount, deluxeCount, premiumCount) {
        const event = new Event('change', { bubbles: true });
        starterCount.dispatchEvent(event);
        deluxeCount.dispatchEvent(event);
        premiumCount.dispatchEvent(event);
    }

  const wizards = document.querySelectorAll('.theme-wizard');

  const tabPillEl = document.querySelectorAll(
    '#pill-tab2 [data-bs-toggle="pill"]'
  );
  const tabProgressBar = document.querySelector('.theme-wizard .progress');

  $('#load_payment').on('load', function () {
    $("#load_payment_wait").hide();
  });

  wizards.forEach(wizard => {
    let tabToggleButtonEl = wizard.querySelectorAll('[data-wizard-step]');
    var forms = wizard.querySelectorAll('[novalidate]');
    var terms = wizard.querySelector('[data-wizard-validation-wizard-checkbox]');
    var starterCount = wizard.querySelector('[data-wizard-validation-wizard-starter]');
    var deluxeCount = wizard.querySelector('[data-wizard-validation-wizard-deluxe]');
    var premiumCount = wizard.querySelector('[data-wizard-validation-wizard-premium]');
    let nextButton = wizard.querySelector('.next button');
    let prevButton = wizard.querySelector('.previous button');
    let cardFooter = wizard.querySelector('.theme-wizard .card-footer');
    let count = 0;

    prevButton.classList.add('d-none');

    // on button click tab change
      nextButton.addEventListener('click', function () {
        $("#totalCreditsAdded").text('');
        $("#load_payment").hide();
        $("#load_payment_wait").show();

        if (count === 0 && forms[count].className.includes('needs-validation') && !terms.checked) {
            forms[count].classList.add('was-validated');
        } else if (count === 1 && forms[count].className.includes('needs-validation') && starterCount.value === "0" && deluxeCount.value === "0" && premiumCount.value === "0") {
            refreshCreditCounters(starterCount, deluxeCount, premiumCount);

            forms[count].classList.add('was-validated');

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            count += 1;
            var tab = new window.bootstrap.Tab(tabToggleButtonEl[count]);
            tab.show();

            if (count === 2) {
                var model = {
                    wasTermsAccepted: terms.checked,
                    starterCount: parseInt(starterCount.value, 10),
                    deluxeCount: parseInt(deluxeCount.value, 10),
                    premiumCount: parseInt(premiumCount.value, 10)
                };

                var tokenElement = document.querySelector('input[name="__RequestVerificationToken"]');
                var token = tokenElement.value;

                $.ajax({
                    url: '',
                    type: 'POST',
                    data: JSON.stringify(model),
                    contentType: 'application/json',
                    headers: {
                        'RequestVerificationToken': token
                    },
                    success: function (response) {
                        if (response.success) {
                            console.log(response.token);

                            $("#load_payment").show();
                            $("#token").val(response.token);
                            $("#totalCreditsAdded").text(response.totalCreditsAdded);
                            $("#send_hptoken").submit();
                        } else {
                            console.error(response.error);
                        }
                    },
                    error: function (xhr, status, error) {
                        console.log(error);
                    }
                });
            }
      }
    });

    prevButton.addEventListener('click', () => {
      count = count - 1;
      const tab = new window.bootstrap.Tab(tabToggleButtonEl[count]);
      tab.show();
    });
    if (tabToggleButtonEl.length) {
      tabToggleButtonEl.forEach((item, index) => {
        /* eslint-disable */

        item.addEventListener('show.bs.tab', e => {
            if (count === index) {
                // do nothing here
            }
            else if (index > count) {
                // deny move forward
                e.preventDefault();
                return null;
            }
            else if (count == 0 && forms[count].className.includes('needs-validation') && !terms.checked) {
                e.preventDefault();
                forms[count].classList.add('was-validated');
                return null;
            } else if (count == 1 && forms[count].className.includes('needs-validation') && starterCount.value === "0" && deluxeCount.value === "0" && premiumCount.value === "0") {
                e.preventDefault();

                refreshCreditCounters(starterCount, deluxeCount, premiumCount);

                forms[count].classList.add('was-validated');
                return null;
            /* eslint-enable */
          }
          count = index;
          // can't go back tab
          if (count === tabToggleButtonEl.length - 1) {
            tabToggleButtonEl.forEach(tab => {
              tab.setAttribute('data-bs-toggle', 'modal');
              tab.setAttribute('data-bs-target', '#error-modal');
            });
          }
          //add done class
          for (let i = 0; i < count; i = i + 1) {
            tabToggleButtonEl[i].classList.add('done');
          }
          //remove done class
          for (let j = count; j < tabToggleButtonEl.length; j = j + 1) {
            tabToggleButtonEl[j].classList.remove('done');
          }
          // card footer remove at last step
          if (count > tabToggleButtonEl.length - 2) {
            item.classList.add('done');
            cardFooter.classList.add('d-none');
          } else {
            cardFooter.classList.remove('d-none');
          }
          // prev-button removing
          if (count > 0) {
            prevButton.classList.remove('d-none');
          } else {
            prevButton.classList.add('d-none');
          }
        });
      });
    }
  });

  // control wizard progressbar
  if (tabPillEl.length) {
    const dividedProgressbar = 100 / tabPillEl.length;
    tabProgressBar.querySelector('.progress-bar').style.width =
      dividedProgressbar + '%';

    tabPillEl.forEach((item, index) => {
      item.addEventListener('show.bs.tab', () => {
        tabProgressBar.querySelector('.progress-bar').style.width =
          dividedProgressbar * (index + 1) + '%';
      });
    });
  }
};

export default wizardInit;
