import utils from "../../utils";
import { echartSetOption } from "./echarts-utils";

/* -------------------------------------------------------------------------- */
/*                          Expert On Radar                                   */
/* -------------------------------------------------------------------------- */

const expertOnRadarInit = () => {
  const EXPERT_ON_RADAR = ".expert-on-radar";

  const $expertOnRadar = document.querySelector(
    EXPERT_ON_RADAR
    );

    function getformatter(params) {
        return "<strong>".concat(params.name, " </strong><br>\n    <div class=\"fs--1 text-500\">\n      <strong>")
            .concat(params.data.dimensionNames[0]).concat("</strong>: ")
            .concat(params.value[0], "  <br>\n      <strong> ")
            .concat(params.data.dimensionNames[1]).concat("</strong>: ")
            .concat(params.value[1], "  <br>\n      <strong>")
            .concat(params.data.dimensionNames[2]).concat("</strong>: ")
            .concat(params.value[2], "  <br>\n      <strong>")
            .concat(params.data.dimensionNames[3]).concat("</strong>: ")
            .concat(params.value[3], "  <br>\n      <strong>")
            .concat(params.data.dimensionNames[4]).concat("</strong>: ")
            .concat(params.value[4], "%  <br>\n    </div>");
    }

  if ($expertOnRadar) {
      const userOptions = utils.getData($expertOnRadar, "options");
      const json = JSON.parse($expertOnRadar.dataset["options"]);
      console.log(json);
      console.log(userOptions);
    const chart = window.echarts.init($expertOnRadar);
      const getDefaultOptions = () => ({
      tooltip: {
        trigger: "item",
        padding: [3, 3],
        backgroundColor: utils.getColor('100'),
        borderColor: utils.getColor('300'),
        textStyle: { color: utils.getColors().dark },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: getformatter,

      },
      radar: {
        splitNumber: 4,
        radius: '55%',
        axisLine: {
          show: true,
          symbol: 'circle',
          symbolSize: [13, 13],
          lineStyle: {
            color: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.5,
              colorStops: [{
                offset: 0.7, color: utils.getColor('100')
              }, {
                offset: 1, color: utils.getColor('400')
              }],
            }
          }
        },
        splitArea: {
          show: false
        },
        splitLine: {
          lineStyle: {
            color: utils.getColor('300'),
          }
        },
        name: {
          textStyle: {
            color: utils.getColor('600'),
            fontWeight: 500
          }
        },
        indicator: []
      },
      series: [
        {
          name: '',
          type: 'radar',
          symbol: 'pin',
          data: [
            {
              dimensionNames: [],
              value: [],
              name: '',
              itemStyle: {
                color: utils.rgbaColor(utils.getColors().warning, 0.5)
              },
              areaStyle: {
                color: [
                  utils.rgbaColor(utils.getColors().warning, 0.24)
                ],

              },
              symbol: "circle",
              symbolSize: 8,
            }
          ]
        }
      ],
      grid: {
        top: 0,
        bottom: '100px'
      }

    });

    echartSetOption(chart, userOptions, getDefaultOptions);
  }
};

export default expertOnRadarInit;
